import React from "react";
import { useTranslation } from "react-i18next";
import Style from "./GiftcardSummary.module.scss";

const GiftCardSummary = ({ formik, basicData }) => {
  const { t } = useTranslation(["common"]);

  const calculatedResult = (formik.values.amount * basicData?.percentage) / 100;

  const customRound = (num) => {
    let status = basicData?.rounding_status;

    if (status === 1) {
      num = Math.round(num * 100) / 100;
    } else if (status === 0) {
      num = Math.floor(num * 100) / 100;
    }

    return num.toFixed(2);
  };

  return (
    <div className={Style.gift_card_summary}>
      <div className={Style.order_summary}>
        <h4 className={`${Style.order_summary_title} mb-0`}> {t("Summary")}</h4>
        <ul className={Style.order_summary_list}>
          <li className={Style.order_summary_list_item}>
            <p className={`${Style.order_label} mb-0`}>{t("Orginal_amount")}</p>
            <p className={`${Style.order_price} mb-0`}>
              <sup>{t("QR")}</sup>
              {formik.errors.amount
                ? "0"
                : formik.values.amount
                ? parseInt(formik.values.amount).toFixed(2)
                : "0"}
            </p>
          </li>
          <li className={Style.order_summary_list_item}>
            <p className={`${Style.order_label} mb-0`}>{t("Bonus_amount")}</p>
            <p className={`${Style.order_price} ${Style.bonus} mb-0`}>
              <sup>{t("QR")}</sup>
              {calculatedResult &&
              !formik.errors.amount &&
              formik.values.amount >= 500 &&
              basicData?.status == 1
                ? customRound(calculatedResult)
                : "0"}
            </p>
          </li>
          <li className={`${Style.order_summary_list_item} ${Style.total}`}>
            <p className={`${Style.order_label} mb-0`}>{t("You_Pay")}</p>
            <p className={`${Style.order_price} ${Style.bonus} mb-0`}>
              <sup>{t("QR")}</sup>
              {formik.errors.amount
                ? "0"
                : formik.values.amount
                ? parseInt(formik.values.amount).toFixed(2)
                : "0"}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GiftCardSummary;
