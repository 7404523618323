import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Style from "./SomeoneSpecial.module.scss";

const SomeoneSpecial = ({ formik, responseError }) => {
  const { t } = useTranslation(["common"]);
  const [remainingChars, setRemainingChars] = useState(300);

  useEffect(() => {
    setRemainingChars(300 - formik.values.message.length);
  }, [formik.values.message]);
  return (
    <div className={Style.wrapper}>
      <div className={Style.input_wrapper}>
        <Form.Group className="form-group text-start">
          <Form.Label>{t("Recipient_Name")}</Form.Label>
          <Form.Control
            type="text"
            id="recpName"
            placeholder={t("Full_Name")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.recpName}
            name="recpName"
          />
        </Form.Group>
        {formik.touched.recpName && formik.errors.recpName ? (
          <div className={`${Style.error_msg} formikError`}>{formik.errors.recpName}</div>
        ) : null}
      </div>
      <div className={Style.input_wrapper}>
        <Form.Group className="form-group text-start">
          <Form.Label>{t("Recipient_mail")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("E_mail")}
            id="recpEmail"
            name="recpEmail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.recpEmail}
          />
        </Form.Group>
        {formik.touched.recpEmail && formik.errors.recpEmail ? (
          <div className={`${Style.error_msg} formikError`}>{formik.errors.recpEmail}</div>
        ) : null}
      </div>
      <div className={Style.input_wrapper}>
        <Form.Group className="form-group text-start">
          <Form.Label>{t("Recipient_Number")}</Form.Label>
          <Form.Control
            type="text"
            id="recpmobile"
            placeholder={t("Mobile_Number")}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            value={formik.values.recpmobile}
            name="recpmobile"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Group>
        {formik.touched.recpmobile && formik.errors.recpmobile ? (
          <div className={`${Style.error_msg} formikError`}>{formik.errors.recpmobile}</div>
        ) : null}
        {responseError && <p className={`${Style.error_msg} formikError`}>{t("mobile_error")}</p>}
      </div>
      <div className={Style.input_wrapper}>
        <Form.Group className="form-group text-start">
          <Form.Label>{t("Senders_Name")}</Form.Label>
          <Form.Control
            type="text"
            id="senderRecpName"
            placeholder={t("Full_Name")}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            value={formik.values.senderRecpName}
            name="senderRecpName"
          />
        </Form.Group>
        {formik.touched.senderRecpName && formik.errors.senderRecpName ? (
          <div className={`${Style.error_msg} formikError`}>{formik.errors.senderRecpName}</div>
        ) : null}
      </div>
      <div className={Style.input_wrapper}>
        <Form.Group className="form-group text-start">
          <Form.Label>{t("Personal_Message")}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={t("Enter_message")}
            id="message"
            name="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            maxLength="300"
          />
         <small>{`${remainingChars} ${t("characters_left")}`}</small>
        </Form.Group>
      </div>
    </div>
  );
};

export default SomeoneSpecial;
